import React from "react";

const Loading = ({ message = "Loading..." }) => {
  return (
    <div className="text-center">
      <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
      <span className="ps-1" role="status">
        {message}
      </span>
    </div>
  );
};

export default Loading;
