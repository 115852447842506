import React from "react";
import NotFound from "../components/NotFound";

const Home = () => {
  return (
    <NotFound/>
  );
};

export default Home;
