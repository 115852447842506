import React from "react";

const Error = ({ message }) => {
  if (!message) return null; // Mesaj boşsa bileşen hiçbir şey döndürmez.

  return (
    <div className="alert alert-danger" role="alert">
      <i className="fa-solid fa-circle-xmark"></i> {message}
    </div>
  );
};

export default Error;
