import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
    config.headers["Accept"] = "*/*";
    config.headers["Cache-Control"] = "no-cache";

    if (process.env.NODE_ENV === "development") {
      console.log("Request Headers in Development:", config.headers);
    } else if (process.env.NODE_ENV === "production") {
      console.log("Request Headers in Production:", config.headers);
    }

    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const status = error.response.status;
      let message;

      switch (status) {
        case 400:
          message = "Lütfen verilerinizi kontrol ediniz.";
          break;
        case 401:
          message = "Erişim izniniz bulunmamaktadır.";
          break;
        case 403:
          message = "Yetkisiz erişim.";
          break;
        case 404:
          message = "Talebiniz bulunamadı.";
          break;
        case 500:
          message = "Sunucu Hatası: Lütfen daha sonra tekrar deneyin.";
          break;
        default:
          message = error.response.data.message || "Beklenmeyen bir hata oluştu.";
      }

      if (process.env.NODE_ENV === "development") {
        console.error("Response Error:", status, message);
      }

      return Promise.reject({ message, status });
    } else if (error.request) {
      const message = "Sunucudan yanıt alınamadı.";
      return Promise.reject({ message, status: null });
    } else {
      if (process.env.NODE_ENV === "development") {
        console.error("Axios Error:", error.message);
      }
      return Promise.reject({ message: error.message, status: null });
    }
  }
);

export default instance;
