import QRCode from "qrcode";

const generateQRCode = async (text) => {
  try {
    const qrCodeDataUrl = await QRCode.toDataURL(text, {
      errorCorrectionLevel: "H", // En yüksek hata düzeltme
      type: "image/png",         // PNG formatında çıktı
      width: 200,                // QR kodunun genişliği
      margin: 2,                 // QR kodunun kenar boşluğu
    });
    return qrCodeDataUrl; // Base64 formatındaki QR kodu döndür
  } catch (error) {
    console.error("QR Code generation failed:", error);
    return null; // Hata durumunda null döndür
  }
};

export default generateQRCode;
