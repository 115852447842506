import React, { useState, useEffect, useRef } from "react";

const CountdownProgress = ({ expireTime }) => {
  const [remainingPercentage, setRemainingPercentage] = useState(100);
  const [formattedRemainingTime, setFormattedRemainingTime] =
    useState("00:00:00");

  const timer = useRef(null); // Timer'ı useRef ile tanımlıyoruz

  useEffect(() => {
    timer.current = setInterval(() => {
      updateProgress();
    }, 1000);

    return () => clearInterval(timer.current); // Timer temizliği
  }, []);

  const updateProgress = () => {
    const now = new Date();
    const expireDate = new Date(expireTime);

    // Toplam süre
    const totalDuration = expireDate - now;

    // Kalan süreyi formatla
    if (totalDuration > 0) {
      const hours = Math.floor(totalDuration / (1000 * 60 * 60));
      const minutes = Math.floor(
        (totalDuration % (1000 * 60 * 60)) / (1000 * 60)
      );

      const seconds = Math.floor((totalDuration % (1000 * 60)) / 1000);

      setFormattedRemainingTime(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    } else {
      setFormattedRemainingTime("00:00:00");
      setRemainingPercentage(100); // Progress tamamen dolsun
      clearInterval(timer.current); // Süre dolunca timer temizlenir
      return;
    }

    // Tam referans süresi (1 gün = 24 saat)
    const fullDuration =
      expireDate - new Date(now.getTime() - 24 * 60 * 60 * 1000);

    // Yüzdeyi hesapla
    const percentage =
      100 - Math.max((totalDuration / fullDuration) * 10000, 0).toFixed(2);

    setRemainingPercentage(percentage);
  };

  return (
    <div className="d-flex flex-column justify-content-center w-100 my-3">
      <div className="d-flex justify-content-between w-100 mb-1">
        <small className="text-muted">Kalan Süre</small>
        <small className="text-muted">{formattedRemainingTime}</small>
      </div>
      <div className="w-100 px-0">
        <div
          className="progress rounded-1"
          style={{ height: "18px" }}
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow={remainingPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{
              width: `${remainingPercentage}%`,
              background:
                "linear-gradient(135deg, #000367, #000367, #0045a4, #462fa5, #8f19a6)", // The gradient you want to apply
              borderRadius: "5px", // Optional: if you want rounded corners on the progress bar
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CountdownProgress;
