import React, { useState, useEffect } from "react";
import generateQRCode from "../services/generateQRCode";
import CountdownProgress from "../components/CountdownProgress";
import Cancel from "../components/Cancel";

const ShowAccount = ({ data }) => {
  const [copyMessage, setCopyMessage] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    const createQRCode = async () => {
      let txt = "blank";
      if (data.paymentMethod === 1) {
        txt = `https://www.papara.com/personal/qr?accountNumber=${data.account.address}&currency=0&amount=${data.amount}`;
      } else {
        txt = data.account.address;
      }

      try {
        const qrCode = await generateQRCode(txt);
        setQrCodeUrl(qrCode);
      } catch (error) {
        console.error("QR kod oluşturulurken bir hata oluştu:", error);
      }
    };

    createQRCode();
  }, [data.account.address, data.paymentMethod, data.amount]);

  if (!data) return null; // Mesaj boşsa bileşen hiçbir şey döndürmez.

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopyMessage("Kopyalandı!"); // Başarılı mesajı
        setTimeout(() => setCopyMessage(""), 2000); // Mesaj 2 saniye sonra kaybolur
      },
      (err) => {
        console.error("Kopyalama işlemi başarısız oldu:", err);
      }
    );
  };

  const getAmount = () => {
    const formatAmount = (amount, decimals) => {
      return amount
        .toFixed(decimals)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    if (data.paymentMethod === 3) {
      return formatAmount(data.cryptoAmount, 6);
    } else {
      return formatAmount(data.amount, 2);
    }
  };

  const getCurrency = () => {
    if (data.paymentMethod === 3) {
      return data.cryptocurrency;
    } else {
      return data.currency;
    }
  };

  console.log(data);
  return (
    <>
      <div>
        <div className="text-center">
          <b>
            <small className="text-muted">Hesap No</small>
          </b>
          <p>
            {data.account.address}
            <small
              className="text-muted ms-1"
              onClick={() => handleCopy(data.account.address)}
              style={{ cursor: "pointer" }}
            >
              <i className="far fa-copy"></i>
            </small>
          </p>
        </div>

        <div className="text-center">
          <b>
            <small className="text-muted">Hesap Sahibi</small>
          </b>
          <p>
            {data.account.fullName}
            <small
              className="text-muted ms-1"
              onClick={() => handleCopy(data.account.fullName)}
              style={{ cursor: "pointer" }}
            >
              <i className="far fa-copy"></i>
            </small>
          </p>
        </div>

        <div className="text-center">
          <b>
            <small className="text-muted">Tutar</small>
          </b>
          <p>
            {getAmount()} <small>{getCurrency()}</small>
            <small
              className="text-muted ms-1"
              onClick={() => handleCopy(data.amount)}
              style={{ cursor: "pointer" }}
            >
              <i className="far fa-copy"></i>
            </small>
          </p>
        </div>

        <div className="d-flex justify-content-center mb-3">
          <img
            className="border rounded p-1"
            src={qrCodeUrl || null}
            alt="Generated QR Code"
            height={180}
          />
        </div>

        <div className="d-flex justify-content-center">
          <div className="alert alert-info" role="alert">
            <small>
              <i className="fa-solid fa-circle-info"></i> Lütfen açıklama ekleye
              <strong>me</strong>yiniz.
            </small>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <CountdownProgress expireTime={data.account.expireTime} />
        </div>

        <div className="d-flex justify-content-center">
          <div className="alert  alert-primary" role="alert">
            <small className="d-flex justify-content-start">
              <span className="me-2">
                <i className="fa-solid fa-triangle-exclamation"></i>
              </span>
              <span>
                Lütfen <strong>15</strong> dk içerisinde yatırım sağlayınız.
                Limit altı işlemler iade edilme<strong>me</strong>ktedir.
              </span>
            </small>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn btn-primary w-100 p-2"
            type="button"
            style={{
              background: "linear-gradient(180deg, #0045a4, #0045a4, #0045a4)",
            }}
          >
            <i className="fa-solid fa-check me-2"></i> Ödemeyi Yaptım
          </button>
        </div>

        <Cancel uniqueKey={data.uniqueKey} />
      </div>
    </>
  );
};

export default ShowAccount;
