import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Transaction from "./pages/Transaction";
import logo from './img/logo.png';
import icon from './img/icon.png';

import './App.css';
import Success from './pages/Success';
import Cancel from './pages/Cancel';

function App() {
  console.log("API URL:", process.env.REACT_APP_API_URL);

  return (
    <div className="app-background"> 
      <div className="container bg-light rounded border shadow-sm py-3 px-4">
        <div className="d-flex justify-content-center mt-3 mb-4">
          <img src={logo} alt="Paylayercore" height="50" />
        </div>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/transaction" element={<Home />} />
            <Route path="/transaction/:id" element={<Transaction />} />
            <Route path="/transaction/success" element={<Success />} />
            <Route path="/transaction/cancel" element={<Cancel />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
