import React from "react";

const Cancel = () => {

  return (
    <div className="d-flex align-items-center justify-content-center">
    <div className="alert alert-success" role="alert">
            <small className="d-flex justify-content-start">
                <span className="me-2"><i className="fa-solid fa-check-double"></i></span>
                <span>Talebiniz başarıyla <strong>iptal</strong> edilmiştir.</span>
            </small>
        </div>
    </div>
  );
};

export default Cancel;