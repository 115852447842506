import React from "react";

const NotFound = () => {
  return (
    <>
        <div className="d-flex align-items-center justify-content-center">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Sayfa Bulunamadı.</p>
                <p className="lead">
                Aradığınız sayfa mevcut değil.
                </p>

            </div>
        </div>
    </>
  );
};

export default NotFound;
