import React from "react";
import api from "../services/axios";
import { useNavigate } from "react-router-dom";

const Cancel = ({ uniqueKey }) => {
  const navigate = useNavigate();

  const handleCancel = async () => {
    try {
      // Gönderilecek veri
      const data = {
        transactionStatus: 4,
        message: null,
      };

      // API isteği
      const response = await api.patch(
        `/api/v1/transaction/updatestatus/${uniqueKey}`,
        data
      );

      // API'den dönen sonuç
      if (response.status === 200) {
        const backdrops = document.querySelectorAll(".modal-backdrop");
        backdrops.forEach((backdrop) => backdrop.remove());
        navigate("/transaction/cancel");
      } else {
        alert("Talebiniz bulunamadı.");
      }
    } catch (error) {
      console.error(
        "Talebiniz iptal edilirken bir hata oluştu:",
        error.message || error
      );
      alert(error.message || "Talebiniz iptal edilirken bir hata oluştu.");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center mb-3 mt-2">
        <button
          className="btn btn-cancel w-100"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#updateModal"
          style={{backgroundColor:'#8f19a6', color:'white'}}
        >
          <i className="fa-solid fa-xmark me-2" style={{color:'white'}}></i> İptal Et
        </button>
      </div>

      <div
        className="modal fade"
        id="updateModal"
        tabIndex="-1"
        aria-labelledby="updateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-reject p-4">
            <div className="modal-body">
              <div className="d-block text-center my-4">
                <i className="fa-regular fa-circle-xmark display-1 text-danger"></i>
              </div>

              <div className="iptal d-block text-center mb-4">
                <h2 className="mb-3">İptal</h2>
                <p>Talebinizi iptal etmek istediğinizden emin misiniz?</p>
                <p>
                  <span className="text-danger fw-semibold">
                    Bu işlem geri alınamaz
                  </span>
                  , ancak iptal işlemi tamamlandıktan sonra yeni bir talep
                  oluşturabilirsiniz.
                </p>
              </div>
              <div className="d-block d-flex justify-content-around">
                <div>
                  <button
                    type="button"
                    className="btn btn-danger rounded-1 shadow me-2 px-4"
                    data-bs-dismiss="modal"
                    style={{ fontSize: "14px" }}
                  >
                    Vazgeç
                  </button>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="btn btn-reject rounded-1 shadow me-2 px-4"
                    style={{ fontSize: "14px" }}
                  >
                    İptal Et
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cancel;
