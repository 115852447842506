import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../services/axios";
import Error from "../components/Error";
import Loading from "../components/Loading";
import ShowAccount from "../components/ShowAccount";
import Cancel from "../components/Cancel";

const Transaction = () => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState({
    account: null,
  });
  const [accountTypes, setAccountTypes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [depositDTO, setDepositDTO] = useState({ accountTypeId: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (_, i) => currentYear + i);
  const regex = {
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    nationalId: /^\d{11}$/,
    dateOfBirth: /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
    cardNumber: /^\d{4} \d{4} \d{4} \d{4}$/,
    cardCvv: /^\d{3,4}$/,
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Format dateOfBirth to YYYY-MM-DD if valid
    if (name === "dateOfBirth" && regex.dateOfBirth.test(value)) {
      const [day, month, year] = value.split("/");
      formattedValue = `${year}-${month}-${day}`;
    }

    // Format card number (add space every 4 digits)
    if (name === "cardNumber") {
      formattedValue = value.replace(/\D/g, ""); // Remove non-digits
      formattedValue = formattedValue.replace(/(\d{4})(?=\d)/g, "$1 "); // Add space
    }

    // Update state
    setDepositDTO((prev) => {
      return { ...prev, [name]: formattedValue };
    });

    // Validate input
    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "email":
        errorMessage = regex.email.test(value)
          ? ""
          : "Geçerli bir email girin.";
        break;
      case "nationalId":
        errorMessage = regex.nationalId.test(value)
          ? ""
          : "TC No 11 haneli olmalıdır.";
        break;
      case "dateOfBirth":
        errorMessage = regex.dateOfBirth.test(value)
          ? ""
          : "Doğum tarihi (GG/AA/YYYY) formatında olmalıdır.";
        break;
      case "cardNumber":
        errorMessage = regex.cardNumber.test(value)
          ? ""
          : "Kart numarası 16 haneli ve her 4 rakamda bir boşluk olmalıdır.";
        break;
      case "cardCvv":
        errorMessage = regex.cardCvv.test(value)
          ? ""
          : "CVV 3 veya 4 haneli olmalıdır.";
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
  useEffect(() => {
    if (transaction.paymentMethod === 7) {
      if (accountTypes && accountTypes.length === 1) {
        setDepositDTO((prevState) => ({
          ...prevState,
          accountTypeId: accountTypes[0].id,
        }));
      }
    }
  }, [depositDTO.accountTypeId]);
  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/api/v1/transaction/${id}`);
        setTransaction(response.data);
      } catch (err) {
        console.error("Transaction data:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [id]);

  
   useEffect(() => {
    if (transaction?.paymentMethod) {
      const fetchAccountTypes = async () => {
        try {
          setLoading(true);
          const response = await api.get(
            `/api/v1/accounttype/${transaction.paymentMethod}`
          );
          setAccountTypes(response.data);
          
          if (response.data.length === 1) {
            setDepositDTO((prevState) => ({
              ...prevState,
              accountTypeId: response.data[0].id,
            }));
            setTransaction((prevTransaction) => ({
              ...prevTransaction,
              account: {
                ...prevTransaction.account,
                accountType: response.data[0],
              },
            }));
          }
        } catch (err) {
          console.error("Account type data:", err.message);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchAccountTypes();
    }
  }, [transaction?.paymentMethod]);

  const handleAccountTypeChange = (event) => {
    setDepositDTO((prevState) => ({
      ...prevState,
      accountTypeId: event.target.value,
    }));
  };

  // const handleAccountTypeChange = (event) => {
  //   const newAccountTypeId = event.target.value;

  //   setDepositDTO((prevState) => {
  //     const updatedDTO = { ...prevState, accountTypeId: newAccountTypeId };
  //     console.log(updatedDTO.accountTypeId); // Yeni değeri burada logluyoruz
  //     return updatedDTO;
  //   });
  // };



  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    depositDTO.companyTransactionId = transaction.id;

    try {
      const endpoint =
        transaction.paymentMethod === 7
          ? "/api/v2/transaction/deposit"
          : "/api/v1/transaction/deposit";
      const response = await api.post(endpoint, depositDTO);

      if (response.data.isSuccess) {
        const updatedAccount = {
          fullName: response.data.fullName,
          accountType: response.data.accountType,
          address: response.data.address,
          url: response.data.url || "", // Null olursa boş string
          redirectToUrl: response.data.redirectToUrl,
          expireTime: response.data.expireTime,
        };

        setTransaction((prevTransaction) => ({
          ...prevTransaction,
          cryptoAmount: response.data.cryptoAmount,
          cryptocurrency: response.data.cryptocurrency,

          transactionStatus: 2, // Transaction status güncelleniyor
          account: updatedAccount, // Yeni hesap bilgileri ekleniyor
        }));

        console.log("Transaction başarıyla güncellendi.", {
          ...transaction,
          transactionStatus: 2,
          account: updatedAccount,
        });
      } else {
        setError(response.data.message || "Beklenmeyen bir hata oluştu.");
      }
    } catch (err) {
      console.error("Deposit işlemi sırasında hata:", err.message);
      setError("Hata oluştu. Lütfen tekrar deneyiniz.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("Transaction değişti:", transaction);
  }, [transaction]);

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;
  if (!transaction) return <Error message="Talebiniz bulunamadı." />;
  if (transaction.transactionType !== 1)
    return <Error message="Talebiniz bulunamadı." />;

  if (transaction.account?.redirectToUrl && transaction.account.url) {
    window.location.href = transaction.account.url;
    return null;
  }

  if (transaction.account != null) return <ShowAccount data={transaction} />;

  return (
    <div>
      <form onSubmit={handleSubmit} name="DepositForm">
        {transaction.paymentMethod === 7 ? (
          <>
            <div className="mb-3">
              <label htmlFor="email" className="form-label mb-0">
                <small>Email</small>
              </label>
              <input
                type="email"
                className="form-control form-control-sm"
                id="email"
                name="email"
                value={depositDTO.email || ""}
                onChange={handleInputChange}
                required
              />
              {validationErrors.email && (
                <small className="text-danger" style={{ fontSize: "12px" }}>
                  {validationErrors.email}
                </small>
              )}
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="phoneNumber" className="form-label mb-0">
                  <small>Telefon No</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={depositDTO.phoneNumber || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="nationalId" className="form-label mb-0">
                  <small>TC No</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="nationalId"
                  name="nationalId"
                  value={depositDTO.nationalId || ""}
                  onChange={handleInputChange}
                  required
                />
                {validationErrors.nationalId && (
                  <small className="text-danger" style={{ fontSize: "12px" }}>
                    {validationErrors.nationalId}
                  </small>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="dateOfBirth" className="form-label mb-0">
                  <small>Doğum Tarihi</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={depositDTO.dateOfBirth || ""}
                  onChange={handleInputChange}
                  required
                />
                {validationErrors.dateOfBirth && (
                  <small className="text-danger" style={{ fontSize: "12px" }}>
                    {validationErrors.dateOfBirth}
                  </small>
                )}
              </div>
              <div className="col">
                <label htmlFor="zipCode" className="form-label mb-0">
                  <small>Posta Kodu</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="zipCode"
                  name="zipCode"
                  value={depositDTO.zipCode || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="cardNumber" className="form-label mb-0">
                <small>Kart Numarası</small>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="cardNumber"
                name="cardNumber"
                value={depositDTO.cardNumber || ""}
                onChange={handleInputChange}
                maxLength="19"
                required
              />
              {validationErrors.cardNumber && (
                <small className="text-danger" style={{ fontSize: "12px" }}>
                  {validationErrors.cardNumber}
                </small>
              )}
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="" className="form-label mb-0">
                  <small>Valid THRU</small>
                </label>
                <div className="input-group input-group-sm">
                  <select
                    id="cardMonth"
                    value={depositDTO.cardMonth}
                    onChange={(e) =>
                      setDepositDTO({
                        ...depositDTO,
                        cardMonth: e.target.value,
                      })
                    }
                    className="form-select"
                    required
                  >
                    <option value="">Ay</option>
                    {Array.from({ length: 12 }, (_, i) => {
                      const month = (i + 1).toString().padStart(2, "0");
                      return (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="cardYear"
                    value={depositDTO.cardYear}
                    onChange={(e) =>
                      setDepositDTO({ ...depositDTO, cardYear: e.target.value })
                    }
                    className="form-select"
                    required
                  >
                    <option value="">Yıl</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="d-flex justify-content-end">
                  <div style={{ width: "150px" }}>
                    <label htmlFor="cardCvv" className="form-label mb-0">
                      <small>CVV</small>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="cardCvv"
                      name="cardCvv"
                      value={depositDTO.cardCvv || ""}
                      onChange={handleInputChange}
                      maxLength="4"
                      required
                    />
                    {validationErrors.cardCvv && (
                      <small
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {validationErrors.cardCvv}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className="mb-3 bg-white px-2 border rounded overflow-auto"
            style={{ minHeight: "300px", maxHeight: "300px" }}
          >
            {accountTypes && accountTypes.length > 0 ? (
              accountTypes.map((accountType, index) => (
                <div key={accountType.id}>
                  <div
                    className="form-check form-check-reverse py-2"
                    style={{ cursor: "pointer" }}
                  >
                    <label
                      className="form-check-label d-flex justify-content-between"
                      htmlFor={accountType.id}
                      style={{ cursor: "pointer" }}
                    >
                      <span>{accountType.name}</span>
                      <input
                        type="radio"
                        className="form-check-input"
                        name="accountTypeId"
                        id={accountType.id}
                        value={accountType.id}
                        checked={
                          depositDTO.accountTypeId === accountType.id
                            ? true
                            : undefined
                        }
                        onChange={handleAccountTypeChange}
                        style={{ cursor: "pointer" }}
                      />
                    </label>
                  </div>
                  {index < accountTypes.length - 1 && (
                    <hr className="m-0 p-0" />
                  )}
                </div>
              ))
            ) : (
              <p>Hesap türleri yüklenemedi.</p>
            )}
          </div>
        )}

        <div className="d-grid gap-2 mt-3">
          <button
            className="btn btn-primary w-100 shadow btn-confirm mb-1"
            type="submit"
            disabled={isSubmitting}
            style={{
              background: "linear-gradient(180deg, #0045a4, #0045a4, #0045a4)",
            }}
          >
            {isSubmitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
                <span role="status"> Gönderiliyor...</span>
              </>
            ) : (
              <span>Gönder</span>
            )}
          </button>
          <Cancel uniqueKey={transaction.uniqueKey} />
        </div>
      </form>
    </div>
  );
};

export default Transaction;
